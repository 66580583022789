import React from "react"
import { Link, graphql } from "gatsby"
// import he from "he"

import "../lib/polyfill.ts"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import BrowseList from "../components/BrowseList"

import css from "./Card.module.scss"

export default props => {
  console.log(props);
  const { data, pageContext } = props
  const { startsWith = "" } = pageContext;

  return (
    <Layout>
      <HeadMeta
        pageTitle={`Starts with: ${startsWith.toUpperCase()}`}
      />
      <PageHeader>
        Starts with: {startsWith.toUpperCase()}
      </PageHeader>
      <Breadcrumb
        route={[
          { name: "Scanned card catalogue", href: "/" },
          {
            name: `Starts with: ${startsWith.toUpperCase()}`,
            href: `/${startsWith.toLowerCase()}/`,
          },
        ]}
      />
      <BrowseList
        placeholderExample={
          data.allCardsCsv.edges[Math.floor(data.allCardsCsv.edges.length / 2)]
            .node.fields.CT_Term
        }
        items={data.allCardsCsv.edges.map(edge => {
          return {
            fields: {
              name: edge.node.fields.CT_Term,
              slug: edge.node.fields.slug,
            },
          }
        })}
      />
    </Layout>
  )
}

export const query = graphql`
  query LetterPageQuery($regex: String!) {
    allCardsCsv(
      filter: {
        fields: { CT_Term: { nin: [null, "", "CT_Term"], regex: $regex } }
      }
    ) {
      edges {
        node {
          fields {
            CT_Term
            CT_ID
            slug
          }
        }
      }
    }
  }
`
