import React from "react"
import { Link } from "gatsby"

import Phrase from "../Phrase"

import css from "./BrowseList.module.scss"
import utilities from "../../styles/utilities.module.scss"

type ListNode = {
  fields: {
    name: string
    slug: string
  }
}

type Props = {
  items?: ListNode[]
  sortAlpha?: boolean
  placeholderPrefix?: string
  placeholderExample?: string
}

const BrowseList: React.FC<Props> = ({
  items = [],
  sortAlpha = false,
  placeholderPrefix = "",
  placeholderExample,
}) => {
  const [filter, setFilter] = React.useState("")

  if (!items.length) {
    return []
  }

  const formId = React.useRef(
    `BrowseList--${Math.round(Math.random() * 999999)}`
  ).current

  if (sortAlpha) {
    items.sort((a, b) => {
      if (a.fields.name < b.fields.name) {
        return -1
      }
      if (a.fields.name > b.fields.name) {
        return 1
      }
      return 0
    })
  }

  const filteredItems = filter.length
    ? items.filter(
        i => i.fields.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      )
    : items

  return (
    <>
      {placeholderPrefix && placeholderPrefix.length && (
        <label
          htmlFor={`${formId}__query`}
          className={utilities.screenreaderOnly}
        >
          {placeholderPrefix}
        </label>
      )}
      <input
        type="search"
        id={`${formId}__query`}
        className={css.browseFilter}
        placeholder={`${
          placeholderPrefix && placeholderPrefix.length
            ? placeholderPrefix + " "
            : ""
        }eg. ${placeholderExample || items[0].fields.name}`}
        onChange={e => setFilter(e.target.value)}
      />
      <ul className={css.browseList}>
        {filteredItems.map((i, key) => (
          <li key={key}>
            <Link to={`/${i.fields.slug}`}>
              <Phrase text={i.fields.name} />
            </Link>
          </li>
        ))}
      </ul>
    </>
  )
}

export default BrowseList
